import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import downloadFile from '../../../../Utils/DownloadFile';

type PropType = {
  isPublic: boolean;
};

const PrivacyStatement: FC<PropType> = ({ isPublic }) => {
  const { i18n } = useTranslation();

  const handleDownloadPrivacyStatement = (): void => {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_FILE_NAME_EN,
      );
    }
  };

  const handleDownloadConsentForm = (): void => {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_FILE_NAME_EN,
      );
    }
  };

  const handleDownloadTermsAndConditions = (): void => {
    if (i18n.language === 'nl') {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NL,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NAME_NL,
      );
    } else {
      downloadFile(
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_EN,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_AND_CONDITIONS_FILE_NAME_EN,
      );
    }
  };

  const termsAndConditions = isPublic ? 'survey.public.terms-and-conditions' : 'survey.terms-and-conditions';

  return (
    <>
      <p>
        <Trans
          i18nKey={termsAndConditions}
          components={{
            1: <br />,
            2: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                onClick={handleDownloadConsentForm}
                type="button"
              />
            ),
            3: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                onClick={handleDownloadTermsAndConditions}
                type="button"
              />
            ),
          }}
        />
      </p>
      {!isPublic && (
        <p>
          <Trans
            i18nKey="survey.privacy-statement"
            components={{
              1: <br />,
              2: (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                  onClick={handleDownloadPrivacyStatement}
                  type="button"
                />
              ),
            }}
          />
        </p>
      )}
    </>
  );
};

export default PrivacyStatement;
